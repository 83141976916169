import { FC, ReactNode, useContext } from "react";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";
import { DesignContext } from "../../context/DesignContext";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { color } = useContext(DesignContext);

  return (
    <div
      className={`w-screen h-screen border-[28px] overflow-hidde `}
      style={{ borderColor: color }}
    >
      <div className="flex items-center justify-center flex-col h-full">
        <div className="sticky top-0 z-50 w-full bg-white">
          <Navigation />
        </div>
        <div className="flex-grow w-full overflow-y-auto">{children}</div>
        <div className="sticky bottom-0 z-50 w-full bg-white">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
