import { FC, ReactNode, useContext } from "react";
import { Link } from "react-router-dom";
import { DesignContext } from "../../context/DesignContext";

interface CustomLinkProps {
  href: string;
  children: ReactNode;
  className: string;
}

const CustomLink: FC<CustomLinkProps> = ({ href, children, ...props }) => {
  const { color } = useContext(DesignContext);

  const pathname = window.location.pathname;

  return (
    <li
      className={props.className}
      style={{ color: pathname === href ? color : "black" }}
    >
      <Link to={href}>{children}</Link>
    </li>
  );
};

export default CustomLink;
