import { FC, useContext, useState } from "react";
import { FaAndroid } from "react-icons/fa";
import { Button } from "@mui/material";
import { DisplayTypes } from "../../common/enum/DisplayTypes";
import { DesignContext } from "../../context/DesignContext";
import DialogBox from "./DialogBox";

interface AppDialogProps {
  appUrl: string;
  title: string;
  type: DisplayTypes;
  description: string;
}

const AppDialog: FC<AppDialogProps> = ({
  appUrl,
  title,
  type, // For future use with other app types
  description,
}) => {
  const { color } = useContext(DesignContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (url: string) => {
    window.open(url, "_blank");
  };

  const maxLength = 50;

  // Function to truncate the text if it exceeds the max length
  const truncateText = (text: string) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div className="w-full h-full">
      <Button
        color="primary"
        onClick={handleClickOpen}
        sx={{ textTransform: "none" }}
        className="w-full h-full"
      >
        <div
          className="flex flex-col justify-center items-center w-full h-full"
          style={{ color: color }}
        >
          <div>
            <FaAndroid className="w-6 h-6 text-zinc-500" />
          </div>
          <div>{truncateText(title)}</div>
        </div>
      </Button>
      <DialogBox
        title={truncateText(title)}
        open={open}
        handleClose={handleClose}
        description={description}
        handleButtonClick={handleButtonClick}
        url={appUrl}
        actionText="Open App"
      />
    </div>
  );
};

export default AppDialog;
