import { createContext, useEffect, useState } from "react";

interface DesignContextValues {
  color: string;
  setColor: (color: string) => void;
}

interface DesignContextProviderProps {
  children: React.ReactNode;
}

const colors = [
    "chocolate",
    "cadetblue",
    "cornflowerblue",
    "goldenrod",
    "darkkhaki",
    "darkseagreen",
    "forestgreen",
    "lightseagreen",
    "olive",
];

export const DesignContext = createContext<DesignContextValues>({
  color: "red",
  setColor: () => {},
});

const getColor = (newNumber: number) => {
  return colors[newNumber];
};

export default function DesignContextProvider({
  children,
}: DesignContextProviderProps) {
  const [colorNumber, setColorNumber] = useState(0);
  const [color, setColor] = useState(colors[colorNumber]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newNumber = colorNumber + 1 >= colors.length ? 0 : colorNumber + 1;
      setColorNumber(newNumber);
      const randomColor = getColor(newNumber);
      setColor(randomColor);
    }, 10000); // Change color every 10 seconds

    return () => clearInterval(intervalId);
  }, [colorNumber]);

  const value = { color, setColor };

  return (
    <DesignContext.Provider value={value}>{children}</DesignContext.Provider>
  );
}
