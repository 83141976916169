import { useState, FC, useEffect } from "react";

interface TypewriterProps {
  texts: string[];
  delay: number;
  pause: number;
}

const Typewriter: FC<TypewriterProps> = ({ texts, delay, pause }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    if (textIndex < texts.length) {
      if (charIndex < texts[textIndex].length) {
        const timeout = setTimeout(() => {
          setDisplayedText(displayedText + texts[textIndex][charIndex]);
          setCharIndex(charIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      } else {
        const timeout = setTimeout(() => {
          setDisplayedText("");
          setCharIndex(0);
          setTextIndex(textIndex + 1);
        }, pause);

        return () => clearTimeout(timeout);
      }
    } else {
      setTextIndex(0);
    }
  }, [charIndex, textIndex, texts, delay, pause, displayedText]);

  return <span>{displayedText}</span>;
};

export default Typewriter;
