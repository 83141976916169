// import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/layout/Layout";
import Home from "./pages/home/Home";
import Resume from "./pages/resume/Resume";
import Contact from "./pages/contact/Contact";
import Portfolio from "./pages/portfolio/Portfolio";

import { analytics } from "./util/firebaseConfig";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DesignContextProvider from "./context/DesignContext";

function App() {
  useEffect(() => {
    if (analytics) {
      console.log("firebase setup complete");
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <main>
          <DesignContextProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/resume" element={<Resume />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/portfolio" element={<Portfolio />} />
              </Routes>
            </Layout>
          </DesignContextProvider>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
