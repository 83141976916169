import { FC } from "react";
import "./Glitch.css";

interface GlitchProps {
  text: string;
}

const Glitch: FC<GlitchProps> = ({ text }) => {
  return (
    <div className="glitch flex justify-center align-middle uppercase">
      {text}
    </div>
  );
};

export default Glitch;
