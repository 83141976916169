import { useRef, useState } from "react";
import Glitch from "../../components/effects/Glitch";
import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import ScrollFloating from "../../components/chips/ScrollFloating";
import DownloadResume from "./components/DownloadResume";

function Resume() {
  const [scrollY, setScrollY] = useState(0);
  const scrollDivRef = useRef(null);

  const scrollEvent = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.preventDefault();
    setScrollY(event.currentTarget.scrollTop);
  };

  return (
    <div
      className="w-full h-full overflow-auto"
      onScroll={(event) => scrollEvent(event)}
      ref={scrollDivRef}
    >
      <div className="w-full h-full place-content-center">
        <Glitch text="Resume" />
      </div>
      <ScrollFloating scrollTop={scrollY} refDiv={scrollDivRef} />
      <AboutMe />
      <DownloadResume />
      <Skills />
      <Experience />
    </div>
  );
}

export default Resume;
