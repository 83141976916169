import { Button } from "@mui/material";
import { useContext } from "react";
import { DesignContext } from "../../../context/DesignContext";

const DownloadResume = () => {
  const { color } = useContext(DesignContext);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/Sasitha_Rajapaksha.pdf"; // Replace with your PDF's path
    link.download = "sasitha_rajapaksha.pdf"; // Set the desired download file name
    link.click();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" // Tailwind styles
      style={{ backgroundColor: color }}
    >
      Download Resume
    </Button>
  );
};

export default DownloadResume;
