import React, { useRef, useState } from "react";
import Glitch from "../../components/effects/Glitch";
import PortfolioTabs from "./PortfolioTabs";
import ScrollFloating from "../../components/chips/ScrollFloating";

function Portfolio() {
  const [scrollY, setScrollY] = useState(0);
  const scrollDivRef = useRef(null);

  const scrollEvent = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.preventDefault();
    setScrollY(event.currentTarget.scrollTop);
  };

  return (
    <div
      className="w-full h-full overflow-y-auto"
      onScroll={(event) => scrollEvent(event)}
      ref={scrollDivRef}
    >
      <ScrollFloating scrollTop={scrollY} refDiv={scrollDivRef} />
      <div className="w-full h-full">
        <div className="w-full h-full place-content-center">
          <Glitch text="Portfolio" />
        </div>
      </div>
      <PortfolioTabs />
    </div>
  );
}

export default Portfolio;
