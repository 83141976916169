const AboutMe = () => {
  return (
    <div className="w-full px-6 pb-12 sm:px-16 lg:px-28 lg:pb-28">
      <div className="font-sans text-left text-2xl pb-10 font-bold">
        Summary
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="text-justify flex-grow lg:w-4/5 lg:pr-3">
            I'm a software engineer with over six years of industry experience
            specializing in creating and building web applications. I have a
            strong background in full-stack development and have been involved
            in the entire cycle of application development. Deep understanding
            of JavaScript frameworks for both front-end and back-end
            development. Additionally, I possess expert-level knowledge in AWS
            and have a high level of understanding of Cloud technologies and
            their various use cases.
          </div>
          <div className="flex-grow lg:w-1/5 p-8 lg:p-8 lg:pt-0 flex items-center justify-center">
            <img src="/images//profile.jpg" alt="Profile" className="max-w-40" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row pt-10 text-left lg:text-center">
          <div className="flex-grow w-1/2 pb-6 lg:pb-0">
            Current location: Bangkok, Thailand
          </div>
          <div className="flex-grow w-1/2">Nationality: Sri Lankan</div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
