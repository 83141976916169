import { FC } from "react";
import ArticleDialog from "./ArticleDialog";
import { DisplayTypes } from "../../common/enum/DisplayTypes";
import AppDialog from "./AppDialog";

interface DisplayChipProps {
  type: DisplayTypes;
  title: string;
  url: string;
  description: string;
}

export const DisplayChip: FC<DisplayChipProps> = ({
  type,
  title,
  url,
  description,
}) => {
  const renderContent = () => {
    switch (type) {
      case DisplayTypes.ARTICLE_MEDIUM:
        return (
          <ArticleDialog
            articleUrl={url}
            title={title}
            type={type}
            description={description}
          />
        );

      case DisplayTypes.MOBILE_APP:
        return (
          <AppDialog
            appUrl={url}
            title={title}
            type={type}
            description={description}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={`w-48 h-48 border-zinc-500 border-2 rounded-md`}>
      {renderContent()}
    </div>
  );
};
