import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { DesignContext } from "../../../context/DesignContext";
import { useContext } from "react";

const BorderLinearProgress = styled(LinearProgress)<{ barColor: string }>(
  ({ theme, barColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor,
    },
  })
);

interface SkillDetailsProps {
  index: number;
  skill: string;
  percentage: number;
}

const SkillDetails = ({ index, skill, percentage }: SkillDetailsProps) => {
  const { color } = useContext(DesignContext);

  return (
    <div className="w-full flex flex-col lg:flex-row py-4" key={index}>
      <div className="flex-grow lg:w-1/4 pb-3 lg:pb-0">{skill}</div>
      <div className="flex-grow lg:w-3/4 lg:px-10">
        <BorderLinearProgress
          variant="determinate"
          value={percentage}
          barColor={color}
        />
      </div>
    </div>
  );
};

const Skills = () => {
  const skills = [
    {
      skill: "Javascript/Typescript (React/Next/Koa/NestJs/Angular)",
      percentage: 90,
    },
    {
      skill: "Cloud (AWS/GCP/Firebase)",
      percentage: 87,
    },
    {
      skill: "Mobile Development (React Native/Flutter)",
      percentage: 65,
    },
    {
      skill:
        "CI/CD (Gitlab Pipelines/GitHub Actions/AWS Pipelines/Circle CI/Terraform)",
      percentage: 85,
    },
    {
      skill: "Python",
      percentage: 70,
    },
    {
      skill: "C#",
      percentage: 50,
    },
    {
      skill: "Sql (Postgres/MySql/Oracle)",
      percentage: 75,
    },
    {
      skill: "NoSql (DynamoDB/MongoDB/Firestore)",
      percentage: 70,
    },
  ];
  return (
    <div className="w-full px-6 pb-12 flex flex-col text-left sm:px-16 lg:px-28 lg:pb-28">
      <div className="text-2xl font-bold font-sans pb-6">Skills</div>
      {skills.map((skill, index) => (
        <SkillDetails
          index={index}
          key={index}
          skill={skill.skill}
          percentage={skill.percentage}
        />
      ))}
    </div>
  );
};

export default Skills;
