import { FaEnvelope, FaWhatsapp, FaMobile } from "react-icons/fa";

const Details = () => {
  return (
    <div className="w-full px-20 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-28">
      <div className="flex flex-col mb-16 sm:mb-0">
        <div className="flex flex-row justify-center items-center mb-4">
          <FaMobile className="w-4 h-4 sm:mr-2" />
          <span className="hidden leading-3 text-lg sm:block">Phone</span>
          <span>/</span>
          <FaWhatsapp className="w-4 h-4 sm:mx-2" />
          <span className="hidden text-lg leading-3 sm:block">WhatsApp</span>
        </div>
        <div>+(66) 80 887 3167</div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center items-center mb-4">
          <FaEnvelope className="w-4 h-4 mr-2" />
          <span className="hidden text-lg leading-3 sm:block">Email</span>
        </div>
        <div>sasitha.iresha@gmail.com</div>
      </div>
    </div>
  );
};

export default Details;
