import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { RxDoubleArrowDown } from "react-icons/rx";
import { DesignContext } from "../../context/DesignContext";

interface ScrollFloatingProps {
  scrollTop: number;
  refDiv: React.MutableRefObject<null>;
}

const ScrollFloating = ({ scrollTop, refDiv }: ScrollFloatingProps) => {
  const { color } = useContext(DesignContext);
  const scrollToTop = () => {
    if (refDiv.current) {
      (refDiv.current as HTMLDivElement).scrollTo({
        top: 0,
        behavior: "smooth", // Optional: for smooth scrolling
      });
    }
  };

  const scrollDown = () => {
    if (refDiv.current) {
      (refDiv.current as HTMLDivElement).scrollTo({
        top: 200,
        behavior: "smooth", // Optional: for smooth scrolling
      });
    }
  };

  return (
    <>
      {scrollTop > 200 && (
        <div className={`fixed bottom-24 right-10 z-50 block`}>
          <IconButton onClick={scrollToTop} style={{ color: color }}>
            <FaArrowUp className="w-6 h-6" />
          </IconButton>
        </div>
      )}

      {scrollTop < 50 && (
        <div
          className={`fixed bottom-24 left-0 right-0 flex justify-center items-center z-50`}
        >
          <IconButton onClick={scrollDown} style={{ color: color }}>
            <RxDoubleArrowDown className="w-10 h-10 animate-bounce" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default ScrollFloating;
