interface Job {
  company: string;
  title: string;
  location: string;
  period: string;
  description: string;
}

interface ExperienceItemProps {
  index: number;
  job: Job;
}

const ExperienceItem = ({ index, job }: ExperienceItemProps) => {
  return (
    <div key={index} className="pb-10">
      <div className="flex flex-col sm:flex-row sm:items-center">
        <span className="pr-4 text-lg font-bold">{job.company}</span>
        <span className="text-sm font-thin">{job.period}</span>
      </div>
      <div className="text-base font-mono">{job.title}</div>
      <div className="text-sm text-justify py-2">{job.description}</div>
      <div className="text-sm font-extralight">{job.location}</div>
    </div>
  );
};

const Experience = () => {
  const jobs: Job[] = [
    {
      company: "CP Axtra (Makro)",
      title: "Software engineer",
      location: "Bangkok, Thailand",
      period: "(December 2023 - Present)",
      description: `
      Focus on integration systems in retail industry and technologies. Mainly with AWS cloud 
      implementations, Javascript/Typescript implementations, Oracle PlSQL and SQL implementations.
      `,
    },
    {
      company: "Cognizant",
      title: "Software engineer",
      location: "Kuala Lumpur, Malaysia",
      period: "(January 2023 - December 2023)",
      description: `
      Develop web applications for Singapore GovTech projects using Koa and React with NestJs framework.
      Main focus with having user friendly UI and security of the applications.
      `,
    },
    {
      company: "Monstyr",
      title: "Software engineer",
      location: "Remote (Singapore)",
      period: "(February 2022 - December 2022)",
      description: `
      Development of Webpage, Mobile application which provide users with promotions and offers in 
      shops and malls in the Singapore.
      `,
    },
    {
      company: "Skoolbag (EdTech Group)",
      title: "Associate Tech Lead",
      location: "Colombo Sri Lanka",
      period: "(September 2020 - February 2022)",
      description: `
      School communication application in Australia. Worked in development of mobile app and multiple 
      websites and unique backend and infrastructure.
      `,
    },
    {
      company: "Haulmatic Technologies",
      title: "Software engineer",
      location: "Colombo Sri Lanka",
      period: "(December 2019 - August 2020)",
      description: `
      Logistic solution startup, I have worked in development of and improvements web application
      and new feature development.
      `,
    },
    {
      company: "99x Technology",
      title: "Software engineer",
      location: "Colombo Sri Lanka",
      period: "(February 2018 - December 2019)",
      description: `
      Worked with multiple projects which are based in Canada and Australia. 
      `,
    },
  ];

  return (
    <div className="w-full pb-12 px-6 text-left sm:px-16 lg:px-28 lg:pb-28">
      <div className="text-2xl font-bold font-sans pb-6">Experience</div>
      {jobs.map((job, index) => (
        <ExperienceItem key={index} index={index} job={job} />
      ))}
    </div>
  );
};

export default Experience;
