import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="container flex flex-row px-12 py-4 justify-between">
      <div>
        <p className="text-xs text-gray-500">
          &copy; 2024 Sasitha. All rights reserved.
        </p>
      </div>
      <div className="text-xs text-gray-500 flex flex-row align-middle">
        <div className="px-2">
          <a
            href="https://www.linkedin.com/in/sasitha-iresh/"
            aria-label="LinkedIn"
          >
            <FaLinkedin className="w-6 h-6" />
          </a>
        </div>
        <div className="px-2">
          <a href="https://github.com/sir21" aria-label="Github">
            <FaGithub className="w-6 h-6" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
