import { ReactNode, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { DisplayChip } from "../../components/chips/DisplayChip";
import { DisplayTypes } from "../../common/enum/DisplayTypes";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface GridPlanProps {
  children: ReactNode;
}

const GridPlane = ({ children }: GridPlanProps) => {
  return (
    <div className="grid grid-cols-1 gap-2 justify-items-center md:grid-cols-2  lg:grid-cols-3 lg:gap-4">
      {children}
    </div>
  );
};

const details = [
  {
    type: DisplayTypes.MOBILE_APP,
    title: "Shopping List",
    url: "https://play.google.com/store/apps/details?id=com.foxirat.shopping2&pcampaignid=web_share",
    description: "Testing app for managing shopping list",
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title:
      "Starting a Project using ASP.NET Core MVC and Entity Framework Core using Visual Studio",
    url: "https://medium.com/@sasitha.iresha/starting-a-project-using-asp-net-core-mvc-and-entity-framework-core-using-visual-studio-69fd8de5e2a0",
    description: `Start new project with ASP.NET for beginner. This article is written in 2018 and probably most  of the things have changed now.`,
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "ASP.NET Core Web API (Part 01)",
    url: "https://medium.com/@sasitha.iresha/asp-net-core-web-api-ec84c9610b44",
    description: `Setup a basic CRUD API using .NET Core.`,
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "How to start Angular project",
    url: "https://medium.com/@sasitha.iresha/how-to-start-angular-project-a3deb79f00bb",
    description: `Details of how to start an angular project. Written with Angular 9`,
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "Upload an image to S3 using Angular and Node.js back end.",
    url: "https://medium.com/@sasitha.iresha/upload-an-image-to-s3-using-angular-and-node-js-back-end-4b67001af070",
    description: "Basic use of uploading a file to S3 bucket using Angular UI.",
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "AWS Lambda DynamoDB Trigger",
    url: "https://medium.com/@sasitha.iresha/aws-lambda-dynamodb-trigger-ded67c05be60",
    description:
      "How to create a trigger from DynamoDB which will initiate lambda function using changed details",
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "Handle CORS in Angular 9",
    url: "https://medium.com/@sasitha.iresha/handle-cors-in-angular-9-d55c85524109",
    description:
      "How to handle CORS of Angular 9 for local project development",
  },
  {
    type: DisplayTypes.ARTICLE_MEDIUM,
    title: "Set git permission using ssh keys in windows 10",
    url: "https://medium.com/@sasitha.iresha/set-git-permission-using-ssh-keys-in-windows-10-fbb6b06e96f0",
    description: "For ease of using GitHub, how to setup SSH token.",
  },
];

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PortfolioTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="w-full px-6 pb-6 sm:px-24 sm:pb-48">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="portfolio tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Articles" {...a11yProps(1)} />
            <Tab label="Apps" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <GridPlane>
            {details.map((detail, index) => (
              <DisplayChip
                type={detail.type}
                title={detail.title}
                url={detail.url}
                key={index}
                description={detail.description}
              />
            ))}
          </GridPlane>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GridPlane>
            {details
              .filter((i) => i.type === DisplayTypes.ARTICLE_MEDIUM)
              .map((detail, index) => (
                <DisplayChip
                  type={detail.type}
                  title={detail.title}
                  url={detail.url}
                  key={index}
                  description={detail.description}
                />
              ))}
          </GridPlane>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <GridPlane>
            {details
              .filter((i) => i.type === DisplayTypes.MOBILE_APP)
              .map((detail, index) => (
                <DisplayChip
                  type={detail.type}
                  title={detail.title}
                  url={detail.url}
                  key={index}
                  description={detail.description}
                />
              ))}
          </GridPlane>
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default PortfolioTabs;
