import { FC, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DesignContext } from "../../context/DesignContext";

interface DialogBoxProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  handleButtonClick: (url: string) => void;
  url: string;
  actionText: string;
}

const DialogBox: FC<DialogBoxProps> = ({
  open,
  handleClose,
  title,
  description,
  handleButtonClick,
  url,
  actionText,
}) => {
  const { color } = useContext(DesignContext);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div>{description}</div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleButtonClick(url)}
          color="primary"
          variant="contained"
          style={{ backgroundColor: color }}
        >
          {actionText}
        </Button>
        <Button onClick={handleClose} color="warning" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
