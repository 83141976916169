import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Fade, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import CustomLink from "./CustomLink";

const links = [
  { display: "Portfolio", path: "/portfolio" },
  { display: "Resume", path: "/resume" },
  { display: "Contact", path: "/contact" },
];

const CustomLinks = () => {
  return (
    <ul className="sm:flex sm:flex-row hidden">
      {links.map((link, index) => (
        <CustomLink key={index} href={link.path} className="px-2">
          {link.display}
        </CustomLink>
      ))}
    </ul>
  );
};

const Navigation = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigateClick = (link: string) => {
    setAnchorEl(null);
    navigate(link);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className="flex flex-row justify-between w-full p-4 items-center">
      <Link to="/" className="text-xl">
        Sasitha
      </Link>
      <CustomLinks />
      <div className="flex sm:hidden">
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FaBars className="w-4 h-4 text-black" />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="flex"
          TransitionComponent={Fade}
          transitionDuration={1000}
        >
          {links.map((link, index) => (
            <MenuItem key={index} onClick={() => navigateClick(link.path)}>
              {link.display}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </nav>
  );
};

export default Navigation;
