import Glitch from "../../components/effects/Glitch";
import Typewriter from "../../components/effects/TypeWriter";

function Home() {
  const texts = [
    "I'm a software developer...",
    "I'm a traveler...",
    "I'm a creative thinker...",
  ];

  return (
    <div className="w-full h-full place-content-center">
      <Glitch text="I'm Sasitha Iresh" />
      <div>
        <Typewriter texts={texts} delay={100} pause={1000} />
      </div>
    </div>
  );
}

export default Home;
