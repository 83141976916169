// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your Firebase configuration from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyCI01PFvkEVPuL3pIUsdb4qVd4BqTFFUaQ",
  authDomain: "sasitha-714bd.firebaseapp.com",
  projectId: "sasitha-714bd",
  storageBucket: "sasitha-714bd.appspot.com",
  messagingSenderId: "785374059866",
  appId: "1:785374059866:web:436734d6ff9a2c930f2763",
  measurementId: "G-XD3DLQVL32",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
