import { useRef, useState } from "react";
import ScrollFloating from "../../components/chips/ScrollFloating";
import Glitch from "../../components/effects/Glitch";
import Details from "./components/Details";

function Contact() {
  const [scrollY, setScrollY] = useState(0);
  const scrollDivRef = useRef(null);

  const scrollEvent = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.preventDefault();
    setScrollY(event.currentTarget.scrollTop);
  };

  return (
    <div
      className="w-full h-full overflow-auto"
      onScroll={(event) => scrollEvent(event)}
      ref={scrollDivRef}
    >
      <div className="w-full h-full place-content-center">
        <Glitch text="Contact" />
      </div>
      <ScrollFloating scrollTop={scrollY} refDiv={scrollDivRef} />
      <Details />
    </div>
  );
}

export default Contact;
